[video-detections] {
  .video-thumb {
    display: inline-block;
  }

  .frame-holder {
    background-color: black;
    background-clip: content-box;
  }

  .duration {
    color: white;
    position: absolute;
    bottom: 82px;
    right: 4px;
    background-color: black;
    font-size: 12px;
    line-height: 12px;
    border-radius: 3px;
  }

  .divider {
    margin-left: 15px;
    margin-right: 15px;
  }

  .media-link {
    background: rgba(0, 0, 0, 0.2);
    right: 0;
    position: absolute;
    color: #007bff;
    padding: 0px 5px;
    margin: 5px;
  }

  .qa-link {
    margin-left: 5px;
  }
}
