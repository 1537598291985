.BrandTable--logoRow {
    display: flex;
    flex-flow: row wrap;
}
.BrandTable--logoRow .BrandTableImgContainer{
    background: rgb(232, 232, 232);
    margin: 5px;
    display: flex;
    padding: 5px;
    height: 60px;
    width: 60px;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.BrandTable--logoRow .BrandTableImgContainer:hover{
    outline: 1px solid #1B365D;
    background-color: rgb(211, 211, 211);
}

.BrandTable--logoRow .BrandTableImgContainer img{
    width: 40px;
}

.BrandTable--logoRow .BrandTableImgContainer .statusCircle {
    width: 11px;
    height: 11px;
    background: red;
    position: absolute;
    bottom: 5px;
    left: 5px;
    border-radius: 50%;
}

@keyframes change-color {
    0% {
        background-color: #E5503F;
    }
    50% {
        background-color: #88BF40;
    }
}

.BrandTable--logoRow .BrandTableImgContainer .statusCircle.red {
    background: #E5503F;
}

.BrandTable--logoRow .BrandTableImgContainer .statusCircle.red:hover,
.BrandTable--logoRow .BrandTableImgContainer .statusCircle.green:hover {
    animation: change-color 1s infinite;
    outline: 1px solid #1B365D;
    width: 12px;
    height: 12px;
}

.BrandTable--logoRow .BrandTableImgContainer .statusCircle.green {
    background: #88BF40;
}

.bg-error {
    background: rgb(211, 47, 47);
    color: #ffffff;
}

.bg-warning {
    background: #FBC02D;
}

.bg-success {
    background: #88BF40;
}
