.modal-footer .left {
  float: left;
  line-height: 34px;
  pointer-events: none;
}
.modal-footer .left .span {
  padding-right: 5px;
}
.modal-footer .left .fa {
  color: #ccc;
}
.Gallery-media {
  position: relative;
  text-align: center;
  background: black;
  overflow: hidden;
  border-radius: 6px 6px 0 0;
}
.GalleryTweet .tweet {
  padding: 20px;
}
.GalleryTweet .tweet .content {
  margin-left: 58px;
}
.GalleryTweet .tweet .content .header .avatar {
  float: left;
  margin-left: -58px;
  width: 48px;
  height: 48px;
}

[lg-photo-modal] [data-toggle="tooltip"] {
  position: absolute;
  visibility: hidden;
}

[lg-photo-modal] .tweet-text {
  float: left;
  line-height: 34px;
  pointer-events: none;
}
.modal-footer .left .span {
  padding-right: 5px;
}
.modal-footer .left .fa {
  color: #ccc;
}
.Gallery-media {
  position: relative;
  text-align: center;
  background: black;
  overflow: hidden;
  border-radius: 6px 6px 0 0;
}
.GalleryTweet .tweet {
  padding: 20px;
}
.GalleryTweet .tweet .content {
  margin-left: 58px;
}
.GalleryTweet .tweet .content .header .avatar {
  float: left;
  margin-left: -58px;
  width: 48px;
  height: 48px;
}

[lg-photo-modal] [data-toggle="tooltip"] {
    position: absolute;
    visibility: hidden;
}

[lg-photo-modal] .tweet-text {
    float: left;
  line-height: 32px;
}

[lg-photo-modal] .thumb-img {
  width: 45px;
  height: 20px;
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
  margin-right: 0px;
  margin-bottom: -5px;
  background-position: center center;
  background-origin: content-box;
  margin-top: 2px;
}

[lg-photo-modal] [data-dismiss="modal"] {
    float: right;
}

[lg-photo-modal] .date {
  margin-right: 5px;
}

[lg-photo-modal] .modal-footer {
  background-color: #dcdcdc;
  color: black;
}

.tooltip-inner {
  color: #000;
  background-color: #cdcdcd;
}

[lg-photo-modal] .tooltip.left .tooltip-arrow {
  border-left-color: #cdcdcd;
}

.full-size-detections {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.full-size-detection {
  border: 1px solid #6c757d;
  border-radius: 4px;
  padding: 6px 12px;
  margin: 0 5px 5px 0;
}

.explore-tab .Gallery-media {
  background-color: rgb(231, 231, 231);
}

.explore-tab .no-data-blob {
  width: 100%;
  left: 0;
  top: 180px;
}

#qaModal {
  overflow: hidden;

  .tasks {
    padding: 10px !important;
  }

  .tasks-list {
    display: flex;
    flex-direction: column;

    .task {
      display: flex;
      justify-content: space-between;

      a:visited {
        color: purple;
      }
    }
  }

  .task-list-header {
    font-weight: 500;
    margin-bottom: 5px;
  }

  .modal-header {
    background-color: #fff;
  }

  .modal-dialog {
    height: inherit;
  }
  
  .modal-content {
    height: inherit;
    background-color: transparent;

    .modal-body {
      background-color: #fff;
      max-height: 80%;
      overflow: auto;
      margin-bottom: 0 !important;
    }
  }

  .modal-footer {
    background-color: #fff;
  }
}
