.workflow-tab {
 .container {
    margin-bottom: 10px;
 }
}

.workflow-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(231, 231, 231);

    img {
        width: 22px;
        height: 22px;
        box-sizing: border-box;
        cursor: pointer;
        align-self: center;
        background: red;
    }
}

.workflow-editor {
    position: relative;

    .jsoneditor-repair,
    .jsoneditor-transform,
    .jsoneditor-sort {
        display: none;
    }
}

.workflow-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.jsoneditor-validation-errors {
    display: none;
}

.workflow-editor-errors {
    color: #ee422e;
    font-size: 16px;
    position: absolute;
    padding-top: 10px;
}

.nav-workflow {
    display: flex;
    align-items: center;

    .workflow-icon {
        margin-right: 5px;
        background-color: currentColor;
        -webkit-mask: url("./layout/square-code.svg") no-repeat center;
        mask: url("./layout/square-code.svg") no-repeat center;
        width: 15px;
        height: 15px;
    }
}
