.ExploreTableImgContainer {
    background: rgb(232, 232, 232);
    padding: 5px;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
}

.ExploreTableImgContainer img {
    width: 100%;
}
