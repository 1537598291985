.modalBrandImage--imageContainer {
    background: rgb(232, 232, 232);
    margin: 5px;
    padding: 5px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: 100%;
}

.modalBrandImage--imageContainer.dark {
    background-color: #565656;
}


.modalBrandImage--imageContainer .invertBackgroundBtn--container {
    position: absolute;
    top: 5px;
    right: 5px;
}
