body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* #region Main Styles */

.ng-cloak {
  display: none !important;
}

select > option {
  color: black;
}

[lg-filter-bar] select.form-control,
[lg-filter-bar] select.form-control:focus {
  margin-left: 6px;
}

.widget .padd {
  /*margin-bottom: 0;*/
  /*padding: 1em 0;*/
  height: 12em;
}

.widget .padd.padd-tight {
  padding: 6px;
}

.list-flow.list-flow-tight {
  width: 90px;
}

.map {
  height: 228px;
}

small {
  font-size: 14px;
}

.text-subtle {
  color: #b7b7b7;
}

.page-title-subtle {
  color: white;
}

.page-title {
  color: #eee;
  margin-right: 12px;
}

.right {
  clear: right;
  float: right;
}

.widget-content .padd:hover {
  cursor: pointer;
  color: white;
  background-color: #52b9e9;
}

.widget-content .padd:hover * {
  color: white;
}

.list-flow {
  margin: 6px;
  float: left;
  width: 110px;
  height: 120px;
  border-bottom: solid 0 transparent;
  padding: 1.5% 0.5%;
}

.name-stack h5 {
  text-align: center;
  padding: 0;
  margin: 0;
  line-height: 18px !important;
}

.search-query {
  margin: 2px auto 8px auto !important;
}

.form-search {
  min-height: 2em;
}

.input-group[class*="col-"] {
  width: 80%;
  margin: 0 1.5em;
}

.user .user-pic {
  display: block;
  margin: auto;
}

.user .user-details {
  margin-left: 100px;
  margin-right: 10px;
}

.widget-content {
  padding: 1em;
  margin: 0;
}

.stacked {
  float: none !important;
  display: block;
  margin: auto;
}

.navbar .brand-title {
  /*margin-left: 4em;*/
  color: #ffffff;
}

.navbar-logo img {
  height: 20px;
}

.today-datas li {
  padding: 20px 14px;
  height: 9em;
}

/*#region Splash */
#splash-page {
  z-index: 99999 !important;
}

#splash-page .bar {
  width: 100%;
}

.page-splash {
  z-index: 99999 !important;
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  opacity: 0.9;
  pointer-events: auto;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

.page-splash-message {
  text-align: center;
  margin: 20% auto 0 auto;
  font-size: 400%;
  font-family: "Segoe UI", Arial, Helvetica, sans-serif;
  font-weight: normal;
  -webkit-text-shadow: 2px 2px #000000;
  text-shadow: 2px 2px #000000;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.15);
  text-transform: uppercase;
  text-decoration: none;
  color: #f58a00;
  padding: 0;
}

.page-splash-message.page-splash-message-subtle {
  margin: 30% auto 0 auto;
  font-size: 200%;
}

.flag-haschanges {
  position: fixed !important;
  display: inline;
}

.fa-asterisk.fa-asterisk-large {
  font-size: 180%;
  vertical-align: middle;
  color: #f58a00;
}

.fa-asterisk.fa-asterisk-alert {
  color: #f58a00;
}

.fa-asterisk-inline {
  padding: 0 4px 0 0;
}

.ngplus-overlay-background {
  top: 0px;
  left: 0px;
  padding-left: 100px;
  position: absolute;
  z-index: 10000;
  height: 100%;
  width: 100%;
  background-color: #808080;
  opacity: 0.2;
}

.ngplus-overlay-content {
  position: absolute;
  /*border: 1px solid #000;*/
  /*background-color: #fff;*/
  font-weight: bold;
  height: 100px;
  width: 300px;
  height: 15em;
  width: 20em;
  z-index: 10000;
  text-align: center;
}

.page-spinner-message {
  text-align: center;
  font-size: 400%;
  font-family: "Segoe UI", Arial, Helvetica, sans-serif;
  font-weight: normal;
  -webkit-text-shadow: 2px 2px #000000;
  text-shadow: 2px 2px #000000;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.15);
  text-transform: uppercase;
  text-decoration: none;
  color: #f58a00;
  padding: 0;
}

.page-spinner-message.page-spinner-message-subtle {
  margin: 30% auto 0 auto;
  font-size: 200%;
}

.overlay-message {
  font-size: 200%;
}
.spinner {
  margin: 20% auto 0 auto;
  left: auto;
  top: auto !important;
}
/*#endregion*/

table th > a {
  font-weight: bold;
}

table th,
table td {
  text-align: left;
  vertical-align: middle;
}

.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
  cursor: pointer;
}

.widget-content.referrer {
  border-width: 0;
}

a {
  cursor: pointer;
}

/* #endregion */

/*#region wrapper for angular ng-include and ng-view animations*/
.view-container {
  position: relative;
  overflow: hidden;
}
/*#endregion */

/*#region Angular ng-include, ng-view, ng-repeat shuffle animations*/

.shuffle-animation.ng-enter,
.shuffle-animation.ng-leave {
  position: relative;
}

.shuffle-animation.ng-enter {
  -moz-transition: ease-out all 0.3s 0.4s;
  -o-transition: ease-out all 0.3s 0.4s;
  -webkit-transition: ease-out all 0.3s 0.4s;
  transition: ease-out all 0.3s 0.4s;
  left: 2em;
  opacity: 0;
}

.shuffle-animation.ng-enter.ng-enter-active {
  left: 0;
  opacity: 1;
}

.shuffle-animation.ng-leave {
  -moz-transition: 0.3s ease-out all;
  -o-transition: 0.3s ease-out all;
  -webkit-transition: 0.3s ease-out all;
  transition: 0.3s ease-out all;
  left: 0;
  opacity: 1;
}

.shuffle-animation.ng-leave.ng-leave-active {
  left: 2em;
  opacity: 0;
}
/*#endregion*/

/*#region Angular ng-include, ng-view, ng-repeat fader animation */
.fader-animation.ng-enter,
.fader-animation.ng-leave,
.fader-animation.ng-move {
  position: relative;
}

.fader-animation.ng-enter,
.fader-animation.ng-leave {
  -webkit-transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) all 0.5s;
  -moz-transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) all 0.5s;
  -o-transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) all 0.5s;
  transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) all 0.5s;
  opacity: 1;
}

.fader-animation.ng-enter,
.fader-animation.ng-leave.ng-leave-active {
  opacity: 0;
}

.fader-animation.ng-enter.ng-enter-active {
  opacity: 1;
}

.fader-animation.ng-move {
  opacity: 0.5;
}
.fader-animation.ng-move.ng-move-active {
  opacity: 1;
}

/*#endregion*/

/*#region Angular ng-show dissolve animation */
.dissolve-animation.ng-hide-remove,
.dissolve-animation.ng-hide-add {
  position: fixed !important;
  display: inline !important;
  -webkit-transition: 0.5s linear all;
  -moz-transition: 0.5s linear all;
  -o-transition: 0.5s linear all;
  transition: 0.5s linear all;
}

.dissolve-animation.ng-hide-remove.ng-hide-remove-active,
.dissolve-animation.ng-hide-add {
  opacity: 1;
}

.dissolve-animation.ng-hide-add.ng-hide-add-active,
.dissolve-animation.ng-hide-remove {
  opacity: 0;
}
/*#endregion */

/*#region toastr */
#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  margin: 4px auto;
}
/*#endregion */

/*#region Responsive */

@media (max-width: 979px) {
  .sidebar-filler {
    width: 200px;
  }

  .nav-collapse {
    clear: none;
  }

  .nav-collapse .nav > li {
    float: left;
  }

  .navbar .btn-navbar {
    display: none;
  }

  .nav-collapse,
  .nav-collapse.collapse {
    height: inherit;
    overflow: inherit;
  }

  .page-splash-message {
    font-size: 300%;
  }

  .btn-group.pull-right {
    float: none !important;
    display: block;
  }
}

@media (min-width: 768px) {
  .sidebar .sidebar-inner {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .sidebar-filler {
    display: none;
  }

  .nav-collapse {
    clear: both;
  }

  .nav-collapse .nav > li {
    float: none;
  }

  .navbar .btn-navbar {
    display: block;
  }

  .nav-collapse,
  .nav-collapse.collapse {
    height: 0;
    overflow: hidden;
  }

  .page-splash-message {
    font-size: 200%;
    margin: 40% auto 0 auto;
  }

  .page-splash-message.page-splash-message-subtle {
    font-size: 150%;
  }

  .sidebar .sidebar-inner {
    height: inherit;
  }
}

@media (max-width: 480px) {
  input.form-control,
  select.form-control,
  textarea.form-control {
    width: 70%;
  }
}

@media (max-width: 320px) {
  .today-datas li {
    padding: 1em 0.5em;
    line-height: 1em;
  }

  .today-datas li i {
    font-size: 2em;
  }

  .today-datas li .datas-text span {
    font-size: 1em;
  }

  .btn {
    margin: 0.2em;
    width: 7em;
  }

  .btn-group > .btn {
    display: block;
    width: 7em;
  }

  .btn-group.pull-right {
    margin: 0.2em 0;
  }

  input,
  select {
    width: 85% !important;
  }

  textarea {
    width: 80%;
  }

  .img-thumbnail.user-pic {
    width: 5em;
  }

  .user .user-details {
    margin-left: 7em;
    margin-right: 0.5em;
  }

  h3 {
    font-size: 1.5em !important;
    line-height: 1.2em !important;
  }
}

/*#endregion */

/* EK: my stuff, will need to split this into separate files */

[lg-filter-bar] {
  border-bottom: 1px solid #ccc;
}
[lg-filter-bar] .form-group {
  border-right: 1px solid #ccc;
}
[lg-filter-bar] .dropdown-menu a > i.fa {
  float: right;
}
[lg-filter-bar] .dropdown-menu {
  right: 0;
  left: inherit;
}
[lg-filter-bar] .dropdown-toggle {
  border-radius: inherit;
  padding-top: 5px;
  padding-bottom: 5px;
}
[lg-filter-bar] [lg-country-complete] {
  margin-left: 5px;
  margin-right: -4px;
}

/* EK: Bootstrap overrides */

nav .nav-tabs {
  border: none;
}

.navbar-brand {
  height: inherit;
  padding: 10px;
}

.row {
  margin-bottom: 15px;
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 15px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}

.carousel-indicators {
  bottom: -31px;
}
.carousel-indicators .active {
  background-color: #ddd;
}
.carousel-indicators li {
  background-color: #ddd;
}

.carousel-control {
  background: none !important;
  width: 7%;
  color: #666;
}

.carousel-control:hover {
  color: #666;
}

.carousel-control.left {
  left: -80px;
}

.carousel-control.right {
  right: -80px;
}

/* EK: Bootstrap overrides end */

[top-brands] .label {
  margin-top: 3px;
  font-size: 85%;
}

[top-brands] .thumb-img {
  width: 45px;
  height: 16px;
  background-color: pink;
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
  border-radius: 15px;
  padding: 3px;
  margin-right: 5px;
  margin-bottom: -3px;
  background-position: center center;
  background-origin: content-box;
  margin-top: 2px;
}

#myCanvas {
  position: absolute;
  opacity: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  transition: opacity 0.25s ease-in-out;
  /* IE special centering */
  left: 0;
  right: 0;
  margin: auto;
}

.ui-autocomplete {
  max-height: 300px;
  overflow-y: auto;
  /* prevent horizontal scrollbar */
  overflow-x: hidden;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border-radius: 4px;
}

.ui-autocomplete::-webkit-scrollbar {
  width: 8px;
}

.ui-autocomplete::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10px;
}

.photo-monitor-grid {
  padding: 0px;
}

.photo-monitor-grid .col-md-3 {
  padding-left: 0px;
}

.Summary .btn-load-more {
  width: 50%;
}

.summary-photo-monitor-grid {
  margin-top: -15px;
  margin-bottom: 10px;
}

.summary-photo-monitor-grid .col-md-5ths {
  padding-right: 0px;
}

.summary-photo-monitor-grid .carousel-inner {
  padding-right: 15px;
}

/* Top Brands */
h4.list-group-item {
  margin: 0px;
}

.divider {
  background-color: #ccc;
  line-height: 25px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.photo-source .tile {
  margin-left: 25px;
  margin-right: 25px;
  /* margin-top: 55px; */
}

.photo-source .tile .item {
  position: relative;
  margin-bottom: 15px;
}

.photo-source .tile .item .bar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  display: block;
  width: 100%;
  font-weight: bold;
  line-height: 27px;
  padding-left: 6px;
}

.photo-source .tile .item .bar.blue {
  background-color: #55acee;
  color: white;
}

.photo-source .fa-2x {
  float: left;
  padding-right: 5px;
  line-height: 27px;
  width: 29px;
}

.fa-twitter {
  color: #55aced;
}

.photo-source .progress {
  border-radius: 0;
  height: 27px;
  margin-bottom: 40px;
}

.photo-source .progress .instagram {
  background-color: #666666;
}

.photo-source .progress-bar {
  min-width: 3em;
  background-color: #55acee;
  line-height: 27px;
  font-size: inherit;
  font-weight: bold;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Sentiment */
.chart {
  margin: 0 auto;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.1) 2%,
    rgba(0, 0, 0, 0) 2%
  );
  background-size: 100% 50px;
  background-position: left top;
  -webkit-padding-start: 0px;
  margin-top: 35px;
  margin-bottom: 50px;
  padding-left: 0px;
}
.chart li {
  position: relative;
  display: table-cell;
  vertical-align: bottom;
  height: 156px;
}
.chart .text {
  text-align: center;
  width: 88px;
}
.chart .happy {
  background: #7e7;
}
.chart .happy:before {
  content: "\f118";
  color: #7e7;
}
.chart .neutral {
  background: #fcd000;
}
.chart .neutral:before {
  content: "\f11a";
  color: #fcd000;
}
.chart .sad {
  background: #fc0000;
}
.chart .sad:before {
  content: "\f119";
  color: #fc0000;
}
.chart span {
  margin: 0 2em;
  display: block;
  background: rgba(209, 236, 250, 0.75);
  animation: draw 1s ease-in-out;
  font: normal normal normal 14px/1 FontAwesome;
}
.chart span:before {
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  text-align: center;
  word-wrap: break-word;
  font-size: 4em;
  bottom: -56px;
}

@keyframes draw {
  0% {
    height: 0;
  }
}
/* End sentiment */

/* Summary geographic map */
#map {
  height: 277px;
}

.leaflet-container {
  background: rgba(118, 218, 255, 0.31);
}

.leaflet-clickable {
  cursor: initial;
}

.leaflet-container {
  cursor: initial;
}
/* End geographic map */

.Summary .frequent-words {
  margin-bottom: 0px;
}

.list-group-item {
  padding: 5px 9px;
  position: relative;
  background-color: transparent;
}

label.list-group-item:hover {
  color: #555;
  background-color: #f5f5f5;
  cursor: pointer;
}

label.list-group-item input {
  display: none;
}

label.list-group-item {
  font-weight: inherit;
}

/* lg-fa-checkbox start */
.lg-fa-checkbox:before {
  color: #ddd;
  content: "\f0c8";
}

.lg-fa-checkbox.selected:before {
  content: "\f14a";
  color: green;
}

label.list-group-item:active .lg-fa-checkbox::before {
  color: darkgreen;
}

label.list-group-item:hover .lg-fa-checkbox::before {
  content: "\f14a";
}
/* lg-fa-checkbox end */

.ProfileTweet-actionList {
  margin-top: 10px;
}

.ProfileTweet-action {
  display: inline-block;
  width: 80px;
}

.ProfileTweet-action a {
  color: #ccc;
  font-size: 16px;
}

.ProfileTweet-action a:hover {
  color: #d22455;
}

.no-data-blob {
  text-align: center;
  position: absolute;
  top: 70px;
  height: 100%;
  background-color: white;
  width: 115%;
  left: -80px;
  z-index: 100;
}

.no-data-blob h1 {
  padding-top: 150px;
}

/* Logo Version Table start */
.logo-version-table .logoImage {
  max-height: 100px;
  max-width: 100px;
}
.logo-version-table tbody .logoColumn {
  text-align: center;
  height: 100px;
  background-color: #e8e8e8;
}
.logo-version-table tbody > tr > td {
  vertical-align: middle;
}

#table_id_length select {
  margin-left: 13px;
}
/* Logo Version Table end */

/* Color Y1 and Y2 axis start */
.c3-axis-y line,
.c3-axis-y .domain {
  stroke: #1f77b4;
}

.c3-axis-y text {
  fill: #1f77b4;
}

.c3-axis-y2 line,
.c3-axis-y2 .domain {
  stroke: #ff7f0e;
}

.c3-axis-y2 text {
  fill: #ff7f0e;
}
/* Color Y1 and Y2 axis end */

#table_id_filter {
  /* override default right align */
  text-align: inherit;
}

.brandName {
  width: 50%;
  display: inline-block;
}

[lg-range-selector] {
  cursor: pointer;
  border: 1px solid #ccc;
  margin-left: 4px;
  display: inline-block;
}

.object-title {
  opacity: 0;
  position: absolute;
  transition: opacity 0.5s;
  color: #000;
  background-color: #cdcdcd;
  padding: 1px 5px;
  font-size: 12px;
  border-radius: 4px;

}

.Gallery-media:hover .object-title {
  display: flex;
  opacity: 1;
}
