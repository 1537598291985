.logoRow img {
    width: 40px;
}

.logoRow .imgContainer {
    background: rgb(232, 232, 232);
    margin: 5px;
    display: flex;
    padding: 5px;
    height: 60px;
    width: 60px;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.logoRow {
    display: flex;
    flex-flow: row wrap;
}

.text-list li {
    list-style: none;
}
